/* eslint-disable class-methods-use-this */
import 'element-closest-polyfill';
import Base from '@studiometa/js-toolkit';
import { isDev } from './config';
import BlockCategories from './components/BlockCategories';
import BlockCollaborations from './components/BlockCollaborations';
import HeaderDesktop from './components/HeaderDesktop';
import HeaderHeight from './components/HeaderHeight';
import HeaderMobile from './components/HeaderMobile';
import HeroSlider from './components/HeroSlider';
import Search from './components/Search';
import SliderProducts from './components/SliderProducts';
import ContactForm from './components/ContactForm';
import { init as initLazyload } from './utils/lazyload';
import { init as initSelectric } from './utils/selectric';

/**
 * Main App class.
 */
class App extends Base {
  static config = {
    log: isDev(),
    name: 'App',
    components: {
      BlockCategories,
      BlockCollaborations,
      HeaderDesktop,
      HeaderHeight,
      HeaderMobile,
      HeroSlider,
      Search,
      SliderProducts,
      ContactForm,
      StoreLocator: () =>
        import(/* webpackChunkName: "StoreLocator" */ './components/StoreLocator'),
    },
  };

  isScroll = false;

  isScrollBottom = false;

  isScrollTop = false;

  /**
   * Mounted.
   *
   * @return {void}
   */
  mounted() {
    // eslint-disable-next-line dot-notation
    window['console'].log(
      '%c Made with ❤ by Studio Meta %c https://www.studiometa.fr',
      'display: inline-block; background-color: #e95b2b; color: #ffffff; font-weight: 500; margin-top: 8px; margin-bottom: 8px; padding: 8px; text-decoration: none !important; border: 2px solid #e95b2b;',
      'display: inline-block; background-color: #ffff; color: #1f2126; font-weight: 500; margin-top: 8px; margin-bottom: 8px; padding: 8px; text-decoration: none !important; border: 2px solid #e95b2b;'
    );

    if (window.scrollY) {
      this.setScroll(true);
    }

    // Lazyload
    initLazyload();
    initSelectric();
  }

  /**
   * Set Scroll
   * @param {boolean} isScroll Is scroll.
   */
  setScroll(isScroll = false) {
    this.isScroll = isScroll;
    if (this.isScroll) {
      this.$el.classList.add('is-scroll');
    } else {
      this.$el.classList.remove('is-scroll');
    }
  }

  /**
   * Set Scroll direction
   * @param {boolean} isScroll Is scroll direction.
   */
  setScrollDirection(scrollDirection = '') {
    if (!scrollDirection) {
      return;
    }

    if (scrollDirection === 'bottom') {
      this.isScrollBottom = true;
      this.isScrollTop = false;
      this.$el.classList.add('is-scroll-bottom');
      this.$el.classList.remove('is-scroll-top');
    } else if (scrollDirection === 'top') {
      this.isScrollTop = true;
      this.isScrollBottom = false;
      this.$el.classList.add('is-scroll-top');
      this.$el.classList.remove('is-scroll-bottom');
    }
  }

  /**
   * Scrolled
   *
   * @param object event Scroll event.
   */
  scrolled({ y, last }) {
    if (y <= 0) {
      this.setScroll(false);
      return;
    }

    if (!this.isScroll) {
      this.setScroll(true);
    }

    if (y > last.y && !this.isScrollBottom) {
      this.setScrollDirection('bottom');
    } else if (y < last.y && !this.isScrollTop) {
      this.setScrollDirection('top');
    }
  }
}

const app = new App(document.documentElement);
app.$mount();

export default app;
