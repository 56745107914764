/* eslint-disable class-methods-use-this */
import Base from '@studiometa/js-toolkit';
import useResize from '@studiometa/js-toolkit/services/resize';
import { isDev } from '../config';

const { props } = useResize();

/**
 * HeaderMobile class.
 */
export default class HeaderHeight extends Base {
  static config = {
    log: isDev(),
    name: 'HeaderHeight',
  };

  /**
   * Get header height
   */
  get height() {
    const { breakpoint } = props();
    const mobileBreakpoints = (
      ((this.headerMobile || {}).$options || {}).activeBreakpoints || ''
    ).split(' ');
    const desktopBreakpoints = (
      ((this.headerDesktop || {}).$options || {}).activeBreakpoints || ''
    ).split(' ');

    if (mobileBreakpoints.includes(breakpoint)) {
      return parseInt(
        this.headerMobile.$refs.topBar.clientHeight + this.headerMobile.$refs.nav.clientHeight,
        10
      );
    }

    if (desktopBreakpoints.includes(breakpoint)) {
      return parseInt(this.headerDesktop.$el.clientHeight, 10);
    }

    return 0;
  }

  /**
   * Mounted
   */
  mounted() {
    this.$html = document.documentElement;

    /* eslint-disable no-underscore-dangle */
    this.headerDesktop = document.querySelector('[data-component="HeaderDesktop"]').__base__;
    this.headerMobile = document.querySelector('[data-component="HeaderMobile"]').__base__;
    /* eslint-enable no-underscore-dangle */

    this.$el.style.height = `${this.height}px`;
  }

  /**
   * Resized
   */
  resized() {
    this.$el.style.height = `${this.height}px`;
  }
}
