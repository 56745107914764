import Base from '@studiometa/js-toolkit';
import withBreakpointObserver from '@studiometa/js-toolkit/decorators/withBreakpointObserver';
import { isDev } from '../config';

/**
 * HeaderMobile class.
 */
export default class HeaderMobile extends withBreakpointObserver(Base) {
  static config = {
    log: isDev(),
    name: 'HeaderMobile',
    options: {
      activeBreakpoints: {
        type: String,
        default: 'xxs xs s m',
      },
    },
    refs: ['topBar', 'nav', 'menuToggle', 'aside', 'menuBtnOpen[]', 'menuBtnClose[]'],
  };

  $html = null;

  isOpen = false;

  /**
   * Mounted
   */
  mounted() {
    // Elements
    this.$html = document.documentElement;

    this.$refs.aside.style.top = `${
      this.$refs.topBar.clientHeight + this.$refs.nav.clientHeight
    }px`;
  }

  /**
   * Resized
   */
  resized() {
    this.$refs.aside.style.top = `${
      this.$refs.topBar.clientHeight + this.$refs.nav.clientHeight
    }px`;
  }

  /**
   * onMenuToggleClick
   */
  onMenuToggleClick() {
    this.$html.classList.toggle('is-menu-open');
    this.isOpen = !this.isOpen;

    if (!this.isOpen) {
      this.$el.querySelectorAll('.is-open').forEach(($el) => {
        $el.classList.remove('is-open');
      });
    }
  }

  /* eslint-disable class-methods-use-this */
  /**
   * onMenuBtnOpenClick
   */
  onMenuBtnOpenClick(event) {
    event.target.closest('.menu-mobile__item').classList.add('is-open');
  }

  /**
   * onMenuBtnCloseClick
   */
  onMenuBtnCloseClick(event) {
    event.target
      .closest('.menu-mobile__item')
      .parentElement.closest('.menu-mobile__item')
      .classList.remove('is-open');
  }
  /* eslint-enable class-methods-use-this */
}
