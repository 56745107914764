/* eslint-disable class-methods-use-this */
import gsap from 'gsap';
import Base from '@studiometa/js-toolkit';
import Video from './Video';
import { isDev } from '../config';

/**
 * HeroSlider class.
 */
export default class HeroSlider extends Base {
  static config = {
    log: isDev(),
    name: 'HeroSlider',
    refs: ['slider', 'items[]', 'arrowPrevious', 'arrowNext'],
    components: {
      Video,
    },
  };

  sliderIndex = 0;

  /**
   * onArrowPreviousClick
   */
  onArrowPreviousClick() {
    this.sliderIndex =
      this.sliderIndex - 1 < 0 ? this.$refs.items.length - 1 : this.sliderIndex - 1;
    this.changeSlide();
  }

  /**
   * onArrowNextClick
   */
  onArrowNextClick() {
    this.sliderIndex =
      this.sliderIndex + 1 > this.$refs.items.length - 1 ? 0 : this.sliderIndex + 1;
    this.changeSlide();
  }

  /**
   * changeSlide
   */
  changeSlide() {
    const $itemsToDisable = this.$refs.items.filter(($item, index) => this.sliderIndex !== index);

    this.$children.Video.forEach((element) => {
      if (element.isPlaying) {
        element.pause();
      }
    });

    gsap.to($itemsToDisable, {
      opacity: 0,
      duration: 1,
      onComplete: (($items) => {
        $items.forEach(($item) => {
          $item.style.zIndex = 0;
        });
      })($itemsToDisable),
    });

    gsap.to(this.$refs.items[this.sliderIndex], {
      opacity: 1,
      duration: 1,
      onComplete: (($item) => {
        $item.style.zIndex = 1;
      })(this.$refs.items[this.sliderIndex]),
    });
  }

  /**
   * getElementIndex
   *
   * @param {element} el Child element
   * @return child index
   */
  getElementIndex(el) {
    return Array.prototype.indexOf.call(el.parentNode.children, el);
  }
}
