import Base from '@studiometa/js-toolkit';
import withBreakpointObserver from '@studiometa/js-toolkit/decorators/withBreakpointObserver';
import withIntersectionObserver from '@studiometa/js-toolkit/decorators/withIntersectionObserver';
import matrix from '@studiometa/js-toolkit/utils/css/matrix';
import { isDev } from '../config';

/**
 * BlockCollaborations class.
 */
export default class BlockCollaborations extends withBreakpointObserver(
  withIntersectionObserver(Base)
) {
  static config = {
    log: isDev(),
    name: 'BlockCollaborations',
    refs: ['links[]', 'images[]'],
    options: {
      activeBreakpoints: {
        type: String,
        default: 'l xl xxl',
      },
    },
  };

  mouse = {
    x: 0,
    y: 0,
    scale: 0.8,
  };

  isVisible = false;

  isVisibleUpdated = false;

  /**
   * Triggered when the root element of the component is intersecting.
   * @param {Array<IntersectionObserverEntry>} entries
   */
  intersected(entries) {
    if (entries[0].isIntersecting) {
      this.isVisible = true;
      this.isVisibleUpdated = true;
      this.handleIsVisible();
    } else if (entries.length > 1) {
      this.isVisible = false;
      this.isVisibleUpdated = true;
      this.handleIsVisible();
    } else {
      this.isVisible = false;
      this.isVisibleUpdated = true;
      this.handleIsVisible();
    }
    this.isVisibleUpdated = false;
  }

  /**
   * handleIsVisible
   */
  handleIsVisible() {
    if (!this.isVisibleUpdated) {
      return;
    }

    if (this.isVisible) {
      this.$refs.images.forEach(($image) => {
        $image.classList.add('fixed');
        $image.classList.remove('absolute');
      });
    } else {
      this.$refs.images.forEach(($image) => {
        $image.classList.add('absolute');
        $image.classList.remove('fixed');
      });
    }
  }

  /**
   * Handle mouse enter link
   */
  onLinksMouseenter(event, index) {
    if (!this.$refs.images[index]) {
      return;
    }

    this.$refs.images.forEach(($image) => {
      $image.classList.add('opacity-0');
      $image.classList.remove('opacity-100');
    });

    this.$refs.images[index].classList.add('opacity-100');
    this.$refs.images[index].classList.remove('opacity-0');
  }

  /**
   * Handle mouse leave link
   */
  onLinksMouseleave(event, index) {
    this.$refs.images[index].classList.add('opacity-0');
    this.$refs.images[index].classList.remove('opacity-100');
  }

  /**
   * Handle mouse down
   */
  onLinksMousedown() {
    this.mouse.scale = 0.7;
  }

  /**
   * Handle mouse up
   */
  onLinksMouseup() {
    this.mouse.scale = 0.8;
  }

  /**
   * Moved.
   */
  moved({ x, y }) {
    if (!this.isVisible) {
      return;
    }

    this.mouse = {
      x,
      y,
      scale: this.mouse.scale,
    };
  }

  /**
   * Ticked.
   */
  ticked() {
    if (!this.isVisible) {
      return;
    }

    this.$refs.images.forEach(($image) => {
      $image.style.transform = matrix({
        translateX: this.mouse.x - $image.clientWidth / 2,
        translateY: this.mouse.y - $image.clientHeight / 2,
        scaleX: this.mouse.scale,
        scaleY: this.mouse.scale,
      });
    });
  }
}
