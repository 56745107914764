/**
 * Test if we are on a dev environment.
 * @return {Boolean}
 */
export const isDev = () =>
  !['jules-pansu.com', 'www.jules-pansu.com'].includes(window.location.hostname);

export default {
  isDev,
};
