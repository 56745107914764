import Base from '@studiometa/js-toolkit';
import withBreakpointObserver from '@studiometa/js-toolkit/decorators/withBreakpointObserver';
import { isDev } from '../config';

/**
 * HeaderDesktop class.
 */
export default class HeaderDesktop extends withBreakpointObserver(Base) {
  static config = {
    log: isDev(),
    name: 'HeaderDesktop',
    options: {
      activeBreakpoints: {
        type: String,
        default: 'l xl xxl',
      },
    },
    refs: [
      'topBar',
      'nav',
      'menuOverlay',
      'submenusClose[]',
      'wrappedSubmenusClose[]',
      'menuPrimaryBtn[]',
      'menuPrimarySubmenu[]',
      'menuPrimarySubmenuWrapper[]',
      'menuPrimarySubmenuLink[]',
      'menuSecondarySubmenuWrapper[]',
      'tester[]',
    ],
  };

  $html = null;

  /**
   * Mounted
   */
  mounted() {
    // Elements
    this.$html = document.documentElement;

    if (this.$refs.menuPrimarySubmenuWrapper) {
      this.$refs.menuPrimarySubmenuWrapper.forEach(($submenuWrapper) => {
        $submenuWrapper.style.paddingTop = `${this.$el.clientHeight}px`;
      });
    }

    if (this.$refs.menuSecondarySubmenuWrapper) {
      this.$refs.menuSecondarySubmenuWrapper.forEach(($submenuWrapper) => {
        $submenuWrapper.style.paddingTop = `${this.$el.clientHeight}px`;
      });
    }
  }

  /**
   * resized
   */
  resized() {
    if (this.$refs.menuPrimarySubmenuWrapper) {
      this.$refs.menuPrimarySubmenuWrapper.forEach(($submenuWrapper) => {
        $submenuWrapper.style.paddingTop = `${this.$el.clientHeight}px`;
      });
    }

    if (this.$refs.menuSecondarySubmenuWrapper) {
      this.$refs.menuSecondarySubmenuWrapper.forEach(($submenuWrapper) => {
        $submenuWrapper.style.paddingTop = `${this.$el.clientHeight}px`;
      });
    }
  }

  /**
   * onMenuOverlayClick
   */
  onMenuOverlayClick() {
    this.submenusClose();
  }

  /**
   * onSubmenuToggle
   */
  onSubmenusCloseClick() {
    this.submenusClose();
  }

  /**
   * onSubmenuToggle
   */
  onWrappedSubmenusCloseClick() {
    this.submenusClose();
  }

  /**
   * onMenuPrimaryBtnClick
   */
  onMenuPrimaryBtnClick(event) {
    const menuId = parseInt(((event.target || {}).dataset || {}).id || -1, 10);

    this.$html.classList.remove('is-submenu-open');

    this.$refs.menuPrimarySubmenu.forEach(($submenu) => {
      const submenuId = parseInt((($submenu || {}).dataset || {}).id || -1, 10);

      if (menuId === submenuId) {
        $submenu.classList.add('is-open');
        this.$html.classList.add('is-submenu-open');
      } else {
        $submenu.classList.remove('is-open');
      }
    });

    this.$refs.menuSecondarySubmenuWrapper.forEach(($submenu) => {
      $submenu.classList.remove('is-open');
    });
  }

  /**
   * onMenuPrimarySubmenuLinkMouseenter
   */
  onMenuPrimarySubmenuLinkMouseenter(event) {
    const menuId = parseInt(((event.target || {}).dataset || {}).id || -1, 10);
    this.showSubmenuCloseButton(); // Show Primary Close Button

    this.$refs.menuSecondarySubmenuWrapper.forEach(($submenu) => {
      const submenuId = parseInt((($submenu || {}).dataset || {}).id || -1, 10);
      if (submenuId === menuId) {
        $submenu.classList.add('is-open');
        this.hideSubmenuCloseButton(); // Hide Primary Close Button
        return;
      }
      $submenu.classList.remove('is-open');
    });
  }

  /* eslint-disable class-methods-use-this */
  /**
   * onMenuSecondarySubmenuWrapperMouseenter
   */
  onMenuSecondarySubmenuWrapperMouseleave(event) {
    event.target.classList.remove('is-open');
    this.showSubmenuCloseButton(); // Show Primary Close Button
  }
  /* eslint-enable class-methods-use-this */

  /**
   * Closes the submenu
   */
  submenusClose() {
    this.$html.classList.remove('is-submenu-open');

    this.$refs.menuPrimarySubmenu.forEach(($submenu) => {
      $submenu.classList.remove('is-open');
    });

    this.$refs.menuSecondarySubmenuWrapper.forEach(($submenu) => {
      $submenu.classList.remove('is-open');
    });
  }

  /**
   * hideSubmenuCloseButton
   */
  hideSubmenuCloseButton() {
    this.$refs.submenusClose.forEach(($closeBtn) => {
      $closeBtn.classList.add('opacity-0');
    });
  }

  /**
   * showSubmenuCloseButton
   */
  showSubmenuCloseButton() {
    this.$refs.submenusClose.forEach((item) => {
      item.classList.remove('opacity-0');
    });
  }
}
