import gsap from 'gsap';
import Base from '@studiometa/js-toolkit';
import useResize from '@studiometa/js-toolkit/services/resize';
import { isDev } from '../config';

const { props } = useResize();

/**
 * Search class.
 */
export default class Search extends Base {
  static config = {
    log: isDev(),
    name: 'Search',
    refs: ['block', 'wrapper', 'toggle[]'],
  };

  $html = null;

  headerDesktop = null;

  headerMobile = null;

  headerDesktopTop = 0;

  headerDesktopHeight = 0;

  headerMobileTop = 0;

  isOpen = false;

  /**
   * isDesktop
   */
  static get isDesktop() {
    return ['l', 'xl', 'xxl'].includes(props().breakpoint);
  }

  /**
   * Mounted.
   */
  mounted() {
    this.$html = document.documentElement;

    /* eslint-disable no-underscore-dangle */
    this.headerDesktop = document.querySelector('[data-component="HeaderDesktop"]').__base__;
    this.headerMobile = document.querySelector('[data-component="HeaderMobile"]').__base__;
    /* eslint-enable no-underscore-dangle */

    this.setBlockHeight();
  }

  /**
   * resized
   */
  resized() {
    this.setBlockHeight();
  }

  /**
   * onToggleClick
   */
  onToggleClick() {
    this.$html.classList.toggle('is-search-open');

    this.isOpen = !this.isOpen;

    if (Search.isDesktop) {
      if (this.isOpen) {
        gsap.to(this.$refs.block, {
          height: this.headerDesktopHeight,
          duration: 0.5,
          ease: 'power3.inOut',
        });
      } else {
        gsap.to(this.$refs.block, {
          height: 0,
          duration: 0.5,
          ease: 'power3.inOut',
        });
      }
    }
  }

  /**
   * setBlockHeight
   */
  setBlockHeight() {
    if (Search.isDesktop) {
      // Get
      this.headerDesktopTop = this.headerDesktop.$refs.topBar.clientHeight;
      this.headerDesktopHeight = this.headerDesktop.$refs.nav.clientHeight;

      // Set
      this.$refs.block.style.top = `${this.headerDesktopTop}px`;
      this.$refs.wrapper.style.height = `${this.headerDesktopHeight}px`;
    } else {
      // Get
      this.headerMobileTop = this.headerMobile.$refs.topBar.clientHeight;

      // Set
      this.$refs.block.style.top = `${this.headerMobileTop}px`;
      this.$refs.wrapper.style.height = '';
    }
  }
}
