import Base from '@studiometa/js-toolkit';
import { isDev } from '../config';

/**
 * BlockCategories class.
 */
export default class BlockCategories extends Base {
  static config = {
    log: isDev(),
    name: 'BlockCategories',
    refs: ['links[]', 'images[]'],
  };

  /**
   * onLinksMouseenter
   */
  onLinksMouseenter(event) {
    const linkID = parseInt(((event.target || {}).dataset || {}).id || -1, 10);

    // Links
    this.$refs.links.forEach(($link) => {
      $link.classList.remove('is-show');
    });

    event.target.classList.add('is-show');

    // Images
    this.$refs.images.forEach(($image) => {
      const imageID = parseInt((($image || {}).dataset || {}).id || -1, 10);

      if (imageID !== linkID) {
        $image.classList.remove('opacity-1');
        $image.classList.add('opacity-0');
        return;
      }

      $image.classList.remove('opacity-0');
      $image.classList.add('opacity-1');
    });
  }
}
