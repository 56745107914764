import Base from '@studiometa/js-toolkit';
import { isDev } from '../config';

/**
 * ContactForm
 */
export default class ContactForm extends Base {
  static config = {
    log: isDev(),
    name: 'ContactForm',
    refs: ['radio[]', 'fieldsGroupPro', 'fieldsGroupProRequired[]'],
  };

  /**
   * onRadioChange
   * @param {*} event
   */
  onRadioChange(event) {
    if (event.target.value === 'professional') {
      this.$refs.fieldsGroupPro.classList.remove('hidden');
      this.$refs.fieldsGroupProRequired.forEach(($el) => {
        $el.setAttribute('required', '');
      });
    } else {
      this.$refs.fieldsGroupPro.classList.add('hidden');
      this.$refs.fieldsGroupProRequired.forEach(($el) => {
        $el.removeAttribute('required');
      });
    }
  }
}
