import $ from 'jquery';
import 'selectric';

// eslint-disable-next-line import/no-mutable-exports
let $selects;

/**
 * Lazyload
 */
export function init() {
  $selects = $('.js-field-select, select.product-combination');

  $selects.each((index, $el) => {
    const $select = $($el);
    const selectricInstance = $select.data('selectric');

    if (!selectricInstance) {
      $select.selectric({
        arrowButtonMarkup:
          '<svg width="10px" height="6.5px" viewBox="0 0 10 6.5" class="button"><polygon fill="currentColor" points="5,6.5 0,1.4 1.4,0 5,3.6 8.6,0 10,1.4"/></svg>',
      });
    }
  });
}

/**
 * Update $selects
 */
export function update() {
  init();
}

export default $selects;
