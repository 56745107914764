/* eslint-disable class-methods-use-this */
import Base from '@studiometa/js-toolkit';
import { isDev } from '../config';

/**
 * HeroSlider class.
 */
export default class Video extends Base {
  static config = {
    log: isDev(),
    name: 'Video',
    refs: ['videoPlayer', 'cover', 'overlay', 'content', 'playBtn'],
  };

  isPlaying = false;

  /**
   * mount component
   * @return {void}
   */
  mounted() {
    this.body = document.querySelector('body');
    this.timeout = null;
  }

  /**
   * Click on video container
   *
   * @return {void}
   */
  onPlayBtnClick() {
    if (!this.isPlaying) {
      this.$refs.videoPlayer.play();
    }
  }

  /**
   * Launch video
   * @return {void}
   */
  play() {
    clearTimeout(this.timeout);
    this.$refs.overlay.classList.add('opacity-0', 'pointer-events-none');
    this.$refs.content.classList.add('opacity-0', 'pointer-events-none');
    this.$refs.cover.classList.add('opacity-0', 'pointer-events-none');
    this.body.classList.add('video-is-playing');
  }

  /**
   * Listening to play event
   * @return {void}
   */
  onVideoPlayerPlay() {
    this.isPlaying = true;
    this.play();
  }

  /**
   * Listening to pause event
   * @return {void}
   */
  onVideoPlayerPause() {
    this.isPlaying = false;
    this.timeout = setTimeout(() => {
      if (!this.isPlaying) {
        this.pause();
      }
    }, 8000);
  }

  /**
   * Pause video
   * @return {void}
   */
  pause() {
    this.$refs.overlay.classList.remove('opacity-0', 'pointer-events-none');
    this.$refs.content.classList.remove('opacity-0', 'pointer-events-none');
    this.$refs.cover.classList.remove('opacity-0', 'pointer-events-none');
    this.body.classList.remove('video-is-playing');
    this.$refs.videoPlayer.pause();
  }
}
