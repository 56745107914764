import Flickity from 'flickity-imagesloaded';
import Base from '@studiometa/js-toolkit';
import useResize from '@studiometa/js-toolkit/services/resize';
import { isDev } from '../config';

const { props } = useResize();

/**
 * SliderProducts class.
 */
export default class SliderProducts extends Base {
  static config = {
    log: isDev(),
    name: 'SliderProducts',
    refs: ['arrowPrevious', 'arrowNext', 'slider', 'products[]'],
  };

  slider = null;

  /**
   * isDraggable
   */
  static get isDraggable() {
    return ['xxs', 'xs', 's', 'm'].includes(props().breakpoint);
  }

  /**
   * Mounted.
   */
  mounted() {
    const { $refs } = this;

    this.slider = new Flickity(this.$refs.slider, {
      cellAlign: 'left',
      contain: true,
      draggable: this.isDraggable,
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true,
      on: {
        ready() {
          $refs.slider.classList.add('is-flickity-ready');
        },
        change() {
          if (!this.selectedIndex) {
            $refs.arrowPrevious.setAttribute('disabled', '');
          } else if (this.selectedIndex && this.cells.length > 1) {
            $refs.arrowPrevious.removeAttribute('disabled');
          }

          if (this.cells.length < 2 || this.selectedIndex >= this.cells.length - 1) {
            $refs.arrowNext.setAttribute('disabled', '');
          } else {
            $refs.arrowNext.removeAttribute('disabled');
          }
        },
      },
    });
  }

  /**
   * onArrowPreviousClick
   */
  onArrowPreviousClick() {
    this.slider.previous();
  }

  /**
   * onArrowNextClick
   */
  onArrowNextClick() {
    this.slider.next();
  }

  /**
   * setDrag
   */
  setDrag(isActive = true) {
    this.slider.options.draggable = isActive;
    this.slider.updateDraggable();
  }

  /**
   * resized
   */
  resized() {
    this.setDrag(SliderProducts.isDraggable);
  }

  /**
   * Destroyed.
   */
  destroyed() {
    if (this.slider) {
      this.$refs.slider.classList.remove('is-flickity-ready');
      this.slider.destroy();
      this.slider = null;
    }
  }
}
