import LazyLoad from 'vanilla-lazyload';

// eslint-disable-next-line import/no-mutable-exports
let lazyload;

/**
 * Lazyload
 */
export function init() {
  if (lazyload) {
    return lazyload;
  }

  lazyload = new LazyLoad({
    // eslint-disable-next-line camelcase
    elements_selector: '.js-lazy',
    threshold: 600,
    // eslint-disable-next-line camelcase
    callback_enter: ($el) => {
      if ($el.classList.contains('js-lazy-placeholder')) {
        $el.lazyLoadPlaceholder = $el.src || '';
      }
    },
    // eslint-disable-next-line camelcase
    callback_loaded: ($el) => {
      const $container = $el.closest('.js-lazy-container');
      if ($container) {
        $container.classList.add('is-lazy-loaded');
      }
    },
    // eslint-disable-next-line camelcase
    callback_error: ($el) => {
      if ($el.lazyLoadPlaceholder) {
        $el.src = $el.lazyLoadPlaceholder || '';
      }
    },
  });

  return lazyload;
}

/**
 * Update lazyload
 */
export function update() {
  if (!lazyload) {
    init();
  }

  lazyload.update();
}

export default lazyload;
